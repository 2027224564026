import { render, staticRenderFns } from "./MContentSave.vue?vue&type=template&id=9c78adf6&scoped=true&"
import script from "./MContentSave.vue?vue&type=script&lang=js&"
export * from "./MContentSave.vue?vue&type=script&lang=js&"
import style0 from "./MContentSave.vue?vue&type=style&index=0&id=9c78adf6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c78adf6",
  null
  
)

export default component.exports